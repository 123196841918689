import React, { useState } from "react";
import { Box, IconButton, Typography, Theme,TextField, styled, Chip } from "@mui/material";
import {
  DeleteForever,
  Add as IconAdd,
  Undo,
} from '@mui/icons-material';
import PrimaryTextInput from "../PrimaryTextInput";
import { Controller, useFieldArray} from "react-hook-form";
import TextDiffComponent from "./TextDiffComponent";
import DateOrDates from "./DateOrDates";
import { borderedSectionStyles, formDiffRedColor } from "./styles";
import theme from "../../theme";
import { FormRemovedDetail } from "./FormRemovedDetail";

const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));


// "education": {
//   "default": [],
//   "description": "Candidate education credentials",
//   "items": {
//       "$ref": "#/$defs/EducationEvent"
//   },
//   "title": "Education",
//   "type": "array"
// },

// "EducationEvent": {
//   "properties": {
//       "institution": {
//           "title": "Institution",
//           "type": "string"
//       },
//       "credential": {
//           "title": "Credential",
//           "type": "string"
//       },
//       "details": {
//           "items": {
//               "type": "string"
//           },
//           "title": "Details",
//           "type": "array"
//       },
//       "dates": {
//           "anyOf": [
//               {
//                   "type": "string"
//               },
//               {
//                   "type": "null"
//               }
//           ],
//           "default": null,
//           "title": "Dates"
//       }
//   },
//   "required": [
//       "institution",
//       "credential",
//       "details"
//   ],
//   "title": "EducationEvent",
//   "type": "object"
// },


// {
//   "dates": "Aug 2012 – Dec 2013",
//   "details": [
//     "GPA: 4.00"
//   ],
//   "credential": "Master of Science in Computer Science",
//   "institution": "Georgia Institute of Technology"
// }


interface ResumeFormEducationProps {
  control: any,
  _required?: boolean,
  diffOriginData?: any,
  showDiff: boolean
}


const ResumeFormEducation: React.FC<ResumeFormEducationProps> = ({ control, _required, diffOriginData, showDiff }) => {
  // eslint-disable-next-line
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'education', // unique name for your Field Array
  });

  const education = control._formValues?.education;

  const [state, setState] = useState<number>(0); // solely to trigger re-render for to have updated awards variable in the component

  return <Root className={classes.root}>
    <Typography>Education
      <IconButton onClick={() => {append({})}}
        disabled={education?.length > 0 && !(education[education.length -1]?.institution || education[education.length -1]?.credential)}>
        <IconAdd/>
      </IconButton>
    </Typography>

    {fields.map((field: any, index: number) => (
      <Box key={field.id} sx={{display: 'flex', position: 'relative', pb: 1}}>

        <Box sx={{position: 'absolute', left: theme.spacing(-1), top: theme.spacing(-1)}}>
          <Chip label={index + 1} size="small" sx={{color: theme.palette.text.secondary}} />
        </Box>

        <Box sx={{flexGrow: 1, ...borderedSectionStyles}}>

          <Controller
            name={`education.${index}.institution`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box>
                <PrimaryTextInput
                  name="institution"
                  label="Institution"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || ''}
                  defaultValue={ diffOriginData?.education?.[index]?.institution || ''}
                  required={_required && true}
                  multiline={true}
                />
              </Box>
            )}
          />

          <Controller
            name={`education.${index}.credential`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box>
                <PrimaryTextInput
                  name="credential"
                  label="Credential"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || '' }
                  defaultValue={ diffOriginData?.education?.[index]?.credential || '' }
                  required={_required && true}
                  multiline={true}
                />
              </Box>
            )}
          />

          <DateOrDates
              formPath={`education.${index}.date_or_dates`}
              dateIsRange={true}
              // dateIsRange={control._formValues?.education?.[index]?.date_is_range}
              control={control}
              required={_required && false}
          />

          <Controller
            name={`education.${index}.details`}
            control={control}
            defaultValue={[]}
            render={({ field: { onChange: onChangeDetails, onBlur, value: values } }) => (
              <>

                <Typography>Education Details
                  <IconButton onClick={() => {onChangeDetails([...values, ''])}}
                    disabled={values.length > 0 && values[values.length - 1] === ''}
                    >
                    <IconAdd/>
                  </IconButton>
                </Typography>

                {values.map((val: any, i: number) => (
                    <Box>
                      <Box sx={{display: 'flex' }}>

                        <Box sx={{flexGrow: '1'}}>

                          <TextField
                            value={val}
                            onChange={(newVal) => onChangeDetails([...(values[i] = newVal.target.value, values)])}
                            size="small"
                            style={{ width: '100%' }}
                            multiline={true}
                            sx={{mb: theme.spacing(0.5) }}
                          />

                          { showDiff && <TextDiffComponent originalText={diffOriginData?.education?.[index]?.details?.[i] || ''}
                            updatedText={val || ''}
                            onTextUpdated={(newVal) => onChangeDetails([...(values[i] = newVal, values)])}
                          /> }

                        </Box>

                        <Box>
                          <IconButton onClick={() => {onChangeDetails([...(values.splice(i, 1), values)])}}>
                            <DeleteForever/>
                          </IconButton>
                        </Box>

                      </Box>

                      

                    </Box>
                ))}

                {diffOriginData?.education?.[index]?.details?.slice(values?.length || 0).map((val: any, i: number) => (
                  
                  <FormRemovedDetail val={val} onChangeDetails={onChangeDetails} values={values} />

                ))}


              </>
            )}
          />

        </Box>

        <Box pt={'13px'}>
          <IconButton onClick={() => {remove(index)}}>
            <DeleteForever/>
          </IconButton>
        </Box>
      </Box>
    ))}

    {fields.length > 0 && <Typography variant="body1" sx={{fontSize: '12px'}}>Add More Education
      <IconButton onClick={() => {append({})}}
        disabled={education?.length > 0 && !(education[education.length -1]?.institution || education[education.length -1]?.credential)}>
        <IconAdd/>
      </IconButton>
    </Typography>}




    {/* Removed education */}
    { showDiff && (diffOriginData?.education?.length > fields?.length) &&
      [...(diffOriginData?.education || [])].slice(fields?.length).map((field: any, index: number) => (

      <Box key={field.id} sx={{display: 'flex'}}>

        <Box sx={{flexGrow: 1, ...borderedSectionStyles,
          position: 'relative',
          border: `6px dashed ${formDiffRedColor}`
        }}>

          <Box>
            <Typography variant="body2" sx={{background: formDiffRedColor, p:1, borderRadius: theme.spacing(0.5)}}>
              Deleted Education{field?.credential ? ':' : ''} {field?.credential}
            </Typography>
          </Box>

          <Box>
            <PrimaryTextInput
              name="institution"
              label="Institution"
              value={ field?.institution || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="credential"
              label="Credential"
              value={ field?.credential || '' }
              multiline={true}
              disabled={true}
            />
          </Box>


          <Box>
            <PrimaryTextInput
              name="date"
              label="Date info"
              value={ field?.rendered_dates || '' }
              multiline={true}
              disabled={true}
            />
          </Box>


              <>

                <Typography>Education Details</Typography>

                {field?.details?.map((val: any, i: number) => (
                      <Box>
                        <Box sx={{display: 'flex' }}>

                          <Box sx={{flexGrow: '1'}}>
                            <TextField
                              value={val}
                              size="small"
                              style={{ width: '100%' }}
                              multiline={true}
                              sx={{mb: theme.spacing(0.5) }}
                              disabled={true}
                            />

                          </Box>

                        </Box>


                      </Box>
                ))}

              </>
        </Box>

        <Box pt={'13px'}>
          <IconButton onClick={() => {append(field)}}>
            <Undo/>
          </IconButton>
        </Box>

      </Box>
    ))}


  </Root>
}



export default ResumeFormEducation;

